var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[(_vm.userData)?_c('div',{staticClass:"register-form"},[_c('form',{ref:"registerForm",attrs:{"action":"javascript:void(0)"}},[(_vm.step === 1)?_c('div',{staticClass:"register-step"},[_c('div',{staticClass:"register-step__title"},[_vm._v("Подтверждение данных")]),_c('div',{staticClass:"register-step__description"},[_vm._v(" Если вы были зарегистрированы — здесь можно восстановить профиль ")]),(!_vm.requestCode)?_c('TextField',{staticClass:"mb-8",attrs:{"type":'email',"label":'Почта*',"rules":[
            _vm.Rules.isRequired(
              _vm.email,
              'Для регистрации нужно заполнить Email адрес'
            ),
            _vm.Rules.isCorrectEmail(_vm.email, 'Некорректный email'),
            !_vm.emailAlreadyExists ||
              'Этот аккаунт зарегистрирован, хотите <a href=/login>войти?</a>' ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}},[_c('button',{staticClass:"\n              register-step__get-code\n              button button_empty-pink\n              ml-4 ml-xs-0\n              mt-xs-4\n            ",attrs:{"disabled":!_vm.canRequestCode},on:{"click":function($event){return _vm.getCode()}}},[_vm._v(" Получить код ")])]):_c('TextField',{attrs:{"type":'tel',"maskString":'####',"readonly":_vm.codeConfirmed,"label":'Код из письма*',"rules":[
            _vm.Rules.isRequired(_vm.letterCode, 'Неправильный код'),
            _vm.codeConfirmed || 'Неправильный код' ],"successState":_vm.codeConfirmed},model:{value:(_vm.letterCode),callback:function ($$v) {_vm.letterCode=$$v},expression:"letterCode"}},[_c('GetCode',{staticClass:"ml-4",attrs:{"refresh":_vm.checkEmail,"args":[_vm.email]}})],1),(_vm.requestCode)?_c('div',{staticClass:"register-step__tip mt-2 mb-8"},[_vm._v(" Отправили письмо с кодом на "+_vm._s(_vm.email)+". "),_c('span',{on:{"click":function($event){_vm.requestCode = false}}},[_vm._v("Изменить email?")])]):_vm._e(),(!_vm.requestSms)?_c('Checkbox',{staticClass:"mt-9",attrs:{"label":"Я отказываюсь использовать номер","type":'checkbox'},model:{value:(_vm.noPhone),callback:function ($$v) {_vm.noPhone=$$v},expression:"noPhone"}}):_vm._e(),(!_vm.requestSms)?_c('TextField',{staticClass:"mt-4",attrs:{"type":'tel',"maskString":'+7 (###) ###-##-##',"placeholder":'+7(---) --- -- --',"label":'Телефон',"disabled":_vm.noPhone === true,"rules":[
            _vm.Rules.isRequired(_vm.phoneNumber, 'Некорректный номер'),
            _vm.Rules.isCorrectPhone(_vm.phoneNumber, 'Некорректный номер'),
            !_vm.phoneAlreadyExists ||
              'Этот аккаунт зарегистрирован, хотите <a href=/login>войти?</a>' ]},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}},[(!_vm.noPhone)?_c('button',{staticClass:"\n              register-step__get-code\n              button button_empty-pink\n              ml-4 ml-xs-0\n              mt-xs-4\n            ",attrs:{"disabled":!_vm.canRequestSms},on:{"click":function($event){return _vm.getSms()}}},[_vm._v(" Получить смс-код ")]):_vm._e()]):_c('TextField',{staticClass:"mt-4",attrs:{"type":'tel',"readonly":_vm.smsConfirmed,"maskString":'####',"label":'Код из смс*',"rules":[
            _vm.Rules.isRequired(_vm.smsCode, 'Неправильный код'),
            _vm.smsConfirmed || 'Неправильный код' ],"successState":_vm.smsConfirmed},model:{value:(_vm.smsCode),callback:function ($$v) {_vm.smsCode=$$v},expression:"smsCode"}},[_c('GetCode',{staticClass:"ml-4",attrs:{"refresh":_vm.checkPhoneResend}})],1),(_vm.requestSms)?_c('div',{staticClass:"register-step__tip mt-2"},[_vm._v(" Отправили смс с кодом на номер +7 "+_vm._s(_vm.phoneNumber)+". "),_c('span',{on:{"click":function($event){_vm.requestSms = false}}},[_vm._v("Изменить номер?")])]):_vm._e(),_c('Checkbox',{staticClass:"mt-9",attrs:{"type":'checkbox',"label":"Я даю свое согласие на <a href='#openPolicy'>обработку персональных данных</a>*","rules":[
            _vm.Rules.isRequired(
              _vm.personalData,
              'Для регистрации нужно согласие на обработку персональных данных'
            ) ]},model:{value:(_vm.personalData),callback:function ($$v) {_vm.personalData=$$v},expression:"personalData"}}),(_vm.codeConfirmed && (_vm.noPhone || _vm.smsConfirmed))?_c('div',{staticClass:"register-step__button button_pink button",on:{"click":function($event){return _vm.validate(2, _vm.changeUserInfo, _vm.userInfo)}}},[_vm._v(" Продолжить ")]):_vm._e()],1):_vm._e(),(_vm.step === 2)?_c('div',{staticClass:"register-step"},[_c('div',{staticClass:"register-step__title"},[_vm._v("Подтверждение данных")]),_c('div',{staticClass:"register-step__description"},[_vm._v(" Если вы были зарегистрированы — здесь можно восстановить профиль ")]),_c('TextField',{staticClass:"mb-8 pass-block",attrs:{"type":_vm.showPass ? 'text' : 'password',"label":'Введите пароль*',"rules":[
            _vm.Rules.isRequired(_vm.password, 'Пароль не соответствует требованиям'),
            _vm.Rules.onlyLatin(_vm.password, 'Пароль не соответствует требованиям'),
            _vm.Rules.minLength(_vm.password, 'Пароль не соответствует требованиям'),
            _vm.Rules.digitsReq(_vm.password, 'Пароль не соответствует требованиям'),
            !_vm.inputError || _vm.inputError ],"successState":(_vm.Rules.isRequired(_vm.password, false) &&
              _vm.Rules.onlyLatin(_vm.password, false) &&
              _vm.Rules.minLength(_vm.password, false) &&
              _vm.Rules.digitsReq(_vm.password, false) &&
              !_vm.inputError) ||
            false},scopedSlots:_vm._u([{key:"append-icon",fn:function(){return [_c('div',{staticClass:"show-icon",on:{"click":function($event){_vm.showPass = !_vm.showPass}}},[_c('svg',{attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M14.5 8C14.5 8 11.5899 12.5 8 12.5C4.41015 12.5 1.5 8 1.5 8C1.5 8 4.41015 3.5 8 3.5C11.5899 3.5 14.5 8 14.5 8Z","stroke":"#D0006F","stroke-linecap":"square"}}),_c('circle',{attrs:{"cx":"8","cy":"8","r":"2.5","stroke":"#D0006F","stroke-linecap":"square"}})])])]},proxy:true},{key:"alternate-error",fn:function(){return [_c('div',{staticClass:"pass-requirements"},[_c('div',{staticClass:"req__title"},[_vm._v("Требования к паролю")]),_c('ul',{staticClass:"req__list mt-2"},[_c('li',{staticClass:"req__point mb-2",class:[
                    _vm.Rules.onlyLatin(_vm.password, false) ? 'success' : 'error' ]},[_vm._v(" Только латинские буквы ")]),_c('li',{staticClass:"req__point",class:[
                    _vm.Rules.minLength(_vm.password, false) ? 'success' : 'error' ]},[_vm._v(" Минимум 8 символов ")]),_c('li',{staticClass:"req__point",class:[
                    _vm.Rules.digitsReq(_vm.password, false) ? 'success' : 'error' ]},[_vm._v(" Минимум одна цифра ")])])])]},proxy:true}],null,false,2811036388),model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('TextField',{staticClass:"pass-block",attrs:{"type":_vm.showPass ? 'text' : 'password',"label":'Повторите пароль*',"rules":[
            _vm.Rules.isRequired(_vm.passwordRepeat, 'Пароли не совпадают'),
            _vm.passwordRepeat === _vm.password || 'Пароли не совпадают' ],"successState":_vm.passwordRepeat === _vm.password},scopedSlots:_vm._u([{key:"append-icon",fn:function(){return [_c('div',{staticClass:"show-icon",on:{"click":function($event){_vm.showPass = !_vm.showPass}}},[_c('svg',{attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M14.5 8C14.5 8 11.5899 12.5 8 12.5C4.41015 12.5 1.5 8 1.5 8C1.5 8 4.41015 3.5 8 3.5C11.5899 3.5 14.5 8 14.5 8Z","stroke":"#D0006F","stroke-linecap":"square"}}),_c('circle',{attrs:{"cx":"8","cy":"8","r":"2.5","stroke":"#D0006F","stroke-linecap":"square"}})])])]},proxy:true}],null,false,2333737708),model:{value:(_vm.passwordRepeat),callback:function ($$v) {_vm.passwordRepeat=$$v},expression:"passwordRepeat"}}),_c('div',{staticClass:"buttons-container"},[_c('div',{staticClass:"register-step__button button_empty-pink back button",on:{"click":function($event){_vm.step--}}},[_vm._v(" Назад ")]),_c('div',{staticClass:"register-step__button button_pink big-button button",on:{"click":function($event){return _vm.validate(null, _vm.Register)}}},[_vm._v(" Зарегистрироваться ")])])],1):_vm._e()]),_c('RegisterFooter')],1):_c('div',{staticClass:"preloader"},[_vm._m(0),_vm._v(" Подождите, данные загружаются ")])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lds-ring"},[_c('div'),_c('div'),_c('div'),_c('div')])}]

export { render, staticRenderFns }