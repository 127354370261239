<template>
  <div class="wrapper">
    <div class="register-form" v-if="userData">
      <form ref="registerForm" action="javascript:void(0)">
        <div class="register-step" v-if="step === 1">
          <div class="register-step__title">Подтверждение данных</div>
          <div class="register-step__description">
            Если вы были зарегистрированы — здесь можно восстановить профиль
          </div>
          <TextField
            :type="'email'"
            v-model="email"
            v-if="!requestCode"
            :label="'Почта*'"
            :rules="[
              Rules.isRequired(
                email,
                'Для регистрации нужно заполнить Email адрес'
              ),
              Rules.isCorrectEmail(email, 'Некорректный email'),
              !emailAlreadyExists ||
                'Этот аккаунт зарегистрирован, хотите <a href=/login>войти?</a>',
            ]"
            class="mb-8"
          >
            <button
              @click="getCode()"
              :disabled="!canRequestCode"
              class="
                register-step__get-code
                button button_empty-pink
                ml-4 ml-xs-0
                mt-xs-4
              "
            >
              Получить код
            </button>
          </TextField>
          <TextField
            :type="'tel'"
            v-model="letterCode"
            :maskString="'####'"
            :readonly="codeConfirmed"
            v-else
            :label="'Код из письма*'"
            :rules="[
              Rules.isRequired(letterCode, 'Неправильный код'),
              codeConfirmed || 'Неправильный код',
            ]"
            :successState="codeConfirmed"
          >
            <GetCode :refresh="checkEmail" :args="[email]" class="ml-4" />
          </TextField>
          <div class="register-step__tip mt-2 mb-8" v-if="requestCode">
            Отправили письмо с кодом на {{ email }}.
            <span @click="requestCode = false">Изменить email?</span>
          </div>
          <Checkbox
            :label="`Я отказываюсь использовать номер`"
            :type="'checkbox'"
            class="mt-9"
            v-if="!requestSms"
            v-model="noPhone"
          />
          <TextField
            :type="'tel'"
            :maskString="'+7 (###) ###-##-##'"
            :placeholder="'+7(---) --- -- --'"
            v-if="!requestSms"
            v-model="phoneNumber"
            :label="'Телефон'"
            :disabled="noPhone === true"
            :rules="[
              Rules.isRequired(phoneNumber, 'Некорректный номер'),
              Rules.isCorrectPhone(phoneNumber, 'Некорректный номер'),
              !phoneAlreadyExists ||
                'Этот аккаунт зарегистрирован, хотите <a href=/login>войти?</a>',
            ]"
            class="mt-4"
          >
            <button
              @click="getSms()"
              v-if="!noPhone"
              :disabled="!canRequestSms"
              class="
                register-step__get-code
                button button_empty-pink
                ml-4 ml-xs-0
                mt-xs-4
              "
            >
              Получить смс-код
            </button>
          </TextField>
          <TextField
            :type="'tel'"
            v-model="smsCode"
            :readonly="smsConfirmed"
            :maskString="'####'"
            v-else
            :label="'Код из смс*'"
            :rules="[
              Rules.isRequired(smsCode, 'Неправильный код'),
              smsConfirmed || 'Неправильный код',
            ]"
            :successState="smsConfirmed"
            class="mt-4"
          >
            <GetCode class="ml-4" :refresh="checkPhoneResend" />
          </TextField>
          <div class="register-step__tip mt-2" v-if="requestSms">
            Отправили смс с кодом на номер +7 {{ phoneNumber }}.
            <span @click="requestSms = false">Изменить номер?</span>
          </div>
          <Checkbox
            class="mt-9"
            :type="'checkbox'"
            :label="`Я даю свое согласие на <a href='#openPolicy'>обработку персональных данных</a>*`"
            :rules="[
              Rules.isRequired(
                personalData,
                'Для регистрации нужно согласие на обработку персональных данных'
              ),
            ]"
            v-model="personalData"
          />
          <div
            v-if="codeConfirmed && (noPhone || smsConfirmed)"
            class="register-step__button button_pink button"
            @click="validate(2, changeUserInfo, userInfo)"
          >
            Продолжить
          </div>
        </div>
        <div class="register-step" v-if="step === 2">
          <div class="register-step__title">Подтверждение данных</div>
          <div class="register-step__description">
            Если вы были зарегистрированы — здесь можно восстановить профиль
          </div>
          <TextField
            :type="showPass ? 'text' : 'password'"
            v-model="password"
            :label="'Введите пароль*'"
            :rules="[
              Rules.isRequired(password, 'Пароль не соответствует требованиям'),
              Rules.onlyLatin(password, 'Пароль не соответствует требованиям'),
              Rules.minLength(password, 'Пароль не соответствует требованиям'),
              Rules.digitsReq(password, 'Пароль не соответствует требованиям'),
              !inputError || inputError,
            ]"
            :successState="
              (Rules.isRequired(password, false) &&
                Rules.onlyLatin(password, false) &&
                Rules.minLength(password, false) &&
                Rules.digitsReq(password, false) &&
                !inputError) ||
              false
            "
            class="mb-8 pass-block"
          >
            <template v-slot:append-icon>
              <div class="show-icon" @click="showPass = !showPass">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.5 8C14.5 8 11.5899 12.5 8 12.5C4.41015 12.5 1.5 8 1.5 8C1.5 8 4.41015 3.5 8 3.5C11.5899 3.5 14.5 8 14.5 8Z"
                    stroke="#D0006F"
                    stroke-linecap="square"
                  />
                  <circle
                    cx="8"
                    cy="8"
                    r="2.5"
                    stroke="#D0006F"
                    stroke-linecap="square"
                  />
                </svg>
              </div>
            </template>
            <template v-slot:alternate-error>
              <div class="pass-requirements">
                <div class="req__title">Требования к паролю</div>
                <ul class="req__list mt-2">
                  <li
                    class="req__point mb-2"
                    :class="[
                      Rules.onlyLatin(password, false) ? 'success' : 'error',
                    ]"
                  >
                    Только латинские буквы
                  </li>

                  <li
                    class="req__point"
                    :class="[
                      Rules.minLength(password, false) ? 'success' : 'error',
                    ]"
                  >
                    Минимум 8 символов
                  </li>

                  <li
                    class="req__point"
                    :class="[
                      Rules.digitsReq(password, false) ? 'success' : 'error',
                    ]"
                  >
                    Минимум одна цифра
                  </li>
                </ul>
              </div>
            </template>
          </TextField>
          <TextField
            :type="showPass ? 'text' : 'password'"
            v-model="passwordRepeat"
            :label="'Повторите пароль*'"
            :rules="[
              Rules.isRequired(passwordRepeat, 'Пароли не совпадают'),
              passwordRepeat === password || 'Пароли не совпадают',
            ]"
            :successState="passwordRepeat === password"
            class="pass-block"
          >
            <template v-slot:append-icon>
              <div class="show-icon" @click="showPass = !showPass">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.5 8C14.5 8 11.5899 12.5 8 12.5C4.41015 12.5 1.5 8 1.5 8C1.5 8 4.41015 3.5 8 3.5C11.5899 3.5 14.5 8 14.5 8Z"
                    stroke="#D0006F"
                    stroke-linecap="square"
                  />
                  <circle
                    cx="8"
                    cy="8"
                    r="2.5"
                    stroke="#D0006F"
                    stroke-linecap="square"
                  />
                </svg>
              </div>
            </template>
          </TextField>
          <div class="buttons-container">
            <div
              class="register-step__button button_empty-pink back button"
              @click="step--"
            >
              Назад
            </div>
            <div
              class="register-step__button button_pink big-button button"
              @click="validate(null, Register)"
            >
              Зарегистрироваться
            </div>
          </div>
        </div>
      </form>
      <RegisterFooter />
    </div>
     <div class="preloader" v-else>
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      Подождите, данные загружаются
    </div>
  </div>
</template>

<script>
import Checkbox from "@/components/form-elements/Checkbox.vue";
import RegisterFooter from "./RegisterFooter.vue";
import TextField from "@/components/form-elements/TextField.vue";
import Select from "@/components/form-elements/Select.vue";
import { mapActions, mapGetters } from "vuex";
import GetCode from "@/components/form-elements/getCode.vue";
import { bus } from "@/main";
export default {
  components: {
    TextField,
    RegisterFooter,
    Checkbox,
    Select,
    GetCode,
  },
  name: "UpdateForm",
  data: () => ({
    subscribePhone: true,
    subscribeEmail: true,
    personalData: false,
    letterCode: "",
    smsCode: "",
    requestCode: false,
    requestSms: false,
    showPass: false,
    email: "",
    phoneNumber: "",
    noPhone: false,
    step: 1,
    password: "",
    passwordRepeat: "",
    mdmId: "",
    userId: 0,
    userData: null,
    regSuccesfull: false,
  }),
  computed: {
    ...mapGetters([
      "Rules",
      "codeConfirmed",
      "inputError",
      "emailAlreadyExists",
      "phoneAlreadyExists",
      "smsConfirmed",
    ]),
    canRequestCode() {
      return this.email && this.Rules.isCorrectEmail(this.email, false);
    },
    canRequestSms() {
      return (
        this.phoneNumber && this.Rules.isCorrectPhone(this.phoneNumber, false)
      );
    },
    userInfo() {
      let obj = {
        id: this.userId,
        fields: {},
      };
      if (this.email) {
        obj.fields.email = this.email;
      }
      if (this.phoneNumber && !this.noPhone) {
        obj.fields.phone = "7"+this.phoneNumber;
      }
      if (this.noPhone) {
        obj.fields.noPhone = true
      }
      obj.fields.default_photo = '/content/cabinet/default-avatar.svg'
      return obj;
    },
  },
  methods: {
    ...mapActions([
      "mdmIdRegister",
      "setPassword",
      "checkEmail",
      "checkEmailCode",
      "clearInputError",
      "changeUserInfo",
      "checkPhoneCode",
      "checkPhoneResend",
      "checkPhone",
      "logout",
      "mdmIdDelete"
    ]),
    async validate(toStep, callback, arg) {
      let res = [];
      bus.$emit("validate", this._uid);
      res = bus.data.result;
      if (!res.includes(false) && res.length) {
        if (callback && typeof callback === "function") {
          let result = await callback(arg);
          if (result) {
            this.step = toStep;
            bus.data.result = [];
          }
        } else {
          bus.data.result = [];
          if (toStep) {
            this.step = toStep;
          }
        }
      }
      bus.data.result = [];
    },
    async getCode() {
      if (this.canRequestCode) {
        this.requestCode = await this.checkEmail(this.email);
      }
    },
    async getSms() {
      if (this.canRequestSms) {
        this.requestSms = await this.checkPhone(this.phoneNumber);
      }
    },
    async Register() {
      let res = await this.setPassword({
        id: this.userId,
        pass: this.password,
        access_token: localStorage.getItem("token"),
      });
      if (res) {
        this.regSuccesfull = true;
        this.$router.push({ name: "MainPage" });
      }
    },
  },
  beforeDestroy() {
    if (!this.regSuccesfull && this.userId) {
      this.mdmIdDelete(this.userId);
    }
  },
  async mounted() {
    if (
      Object.keys(this.$route.query).includes("mdm_id") &&
      Object.keys(this.$route.query).includes("email")
    ) {
      this.mdmId = this.$route.query.mdm_id;
      this.email = this.$route.query.email;
      this.userData = await this.mdmIdRegister({
        mdm_id: this.mdmId,
        email: this.email,
      });
      if (this.userData) {
        localStorage.setItem("token", this.userData.access_token);
        localStorage.setItem("refresh", this.userData.refresh);
        this.userId = this.userData.id;
        this.phoneNumber = this.userData.phone;
      }
    }
  },
  watch: {
    step() {
      if (this.step) {
        this.$emit("changeStep", this.step);
      }
    },
    async emailAlreadyExists() {
      setTimeout(() => {
        this.validate();
      }, 100);
    },
    async phoneAlreadyExists() {
      setTimeout(() => {
        this.validate();
      }, 100);
    },
    async letterCode() {
      if (this.letterCode.length >= 4) {
        await this.checkEmailCode({code: this.letterCode, subscribe: this.subscribeEmail});
        this.validate();
      }
    },
    async smsCode() {
      if (this.smsCode.length >= 4) {
        await this.checkPhoneCode({code: this.smsCode, subscribe: this.subscribePhone});
        this.validate();
      }
    },
    async inputError() {
      setTimeout(() => {
        this.validate();
      }, 100);
    },
    async password() {
      if (this.inputError) {
        this.clearInputError();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
}
.register-form {
  margin: 0 auto;
  width: 592px;

  @media screen and (max-width: 1220px) {
    width: 100%;
    margin: 40px 0 0;
  }
}

.register-step {
  &__title {
    margin-bottom: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 29px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 23px;
    }
  }

  &__description {
    margin-bottom: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #3c4242;

    &_pink {
      text-decoration-line: underline;
      color: #d0006f;
      cursor: pointer;
    }
  }

  &__tip {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #b2b4b4;

    & span {
      text-decoration-line: underline;
      color: #d0006f;
      cursor: pointer;
    }
  }
  &__button.back {
    width: 92px;
    margin-right: 16px;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-top: 16px;
      margin-right: 0;
    }
  }
  &__button {
    margin-top: 32px;
    width: 162px;
    height: 46px;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  &__get-code {
    height: 40px;
    font-size: 16px;
    line-height: 23px;
    padding: 8px 16px;
  }

  .big-button {
    width: 229px;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }

  .buttons-container {
    display: flex;

    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }
  .show-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}

.preloader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #3c4242;
}
</style>

<style>
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #c4d600 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>